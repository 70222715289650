.v1-main-header {
    width: 100%;
}

.v1-main-sub-header {
    width: 90vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;

}

.v1-main-sub-header .card {
    border-radius: 10px;
    /* background-color: red; */
    text-align: center;
    font-weight: 800;
    color: black;
    border: none !important;
}

.v1-main-sub-header .card h5 {
    margin: 5px;
}

.v1-main-sub-header .cardbig {
    width: 28%;
}

.v1-main-sub-header .cardsmall {
    width: 18%;
}

.v1-subbody {
    display: flex;
    justify-content: space-around;
}
.v1-subbody2 {
    display: flex;
    justify-content: space-around;
}
.count-line {
    height: 0px !important;
    margin-top: 5px;
}

.count-line::before {
    background: none !important;
}

.v1-subbodyFont {
    font-size: 22px;
    line-height: 1.5;
    letter-spacing: 1px;
}

.v1-subbodyFont div {
    text-align: center;
}

.v1-subbodyFont svg {
    height: 20px;
    width: 20px;
}

.v1-subbodyFont p {
    font-weight: 600;
    color: #333F48;
}

.pending .switch-value-bus .active {
    background-color: #B2292E !important;
    border-color: #D9534F !important;
}



.switch-box {
    width: 85px !important;
    height: 27px !important;
}

.pending .switch-icon-warn {
    border-color: #D86018 !important;
}

.pending .switch-value-warn.active {
    background-color: #D86018 !important;
    border-color: #D86018 !important;
}

.switch-value-warn {
    padding-left: 40px !important;
    display: flex;
    white-space: nowrap;
    padding-left: 20px;
    /* padding-right: 10px; */
    height: 100%;
    border: 1px solid #A2AAA4;
    border-left: 0;
    border-radius: 50px 50px 50px 50px;
}

.pending.switch-box .switch-icon {
    border-color: #F1C400;
}

.switch-value-flag {
    padding-left: 40px !important;
    display: flex;
    white-space: nowrap;
    padding-left: 20px;
    /* padding-right: 10px; */
    height: 100%;
    border: 1px solid #F1C400;
    border-left: 0;
    border-radius: 50px 50px 50px 50px;
}

.switch-value.critical.active {
    background-color: #B2292E;
    color: white;
    font-weight: 800;
    border-color: #D9534F !important;
}

.switch-value.critical.inactive {
    background-color: #ffffff;
    color: rgb(7, 5, 5);
    font-weight: 800;
    border-color: #D9534F !important;
}

.switch-value.major.active {
    background-color: #D9534F;
    color: white;
    font-weight: 800;
    border-color: #D9534F !important;
}

.switch-value.major.inactive {
    background-color: #ffffff;
    color: rgb(7, 5, 5);
    font-weight: 800;
    border-color: #fd7e14 !important;
}

.switch-value.minor.active {
    background-color: #F1C400;
    color: white;
    font-weight: 800;
    border-color: #f1c400 !important;
}


.switch-value.normal.active {
    background-color: #4726da;
    color: rgb(255, 255, 255);
    font-weight: 800;
    border-color: #291b95 !important;
}


.switch-value.minor.inactive {
    background-color: #ffffff;
    color: rgb(7, 5, 5);
    font-weight: 800;
    border-color: #c4a51b !important;
}

.switch-value.normal.inactive {
    background-color: #ffffff;
    color: rgb(7, 5, 5);
    font-weight: 800;
    border-color: #2c1a9c !important;
}
.switch-value.inactive span {
    text-align: center;
    margin-left: 15px;
}

.switch-value.active span {
    text-align: center;
    margin-left: 15px;
}

.switch-icon-critical {
    border-color: #B2292E !important;
}

.switch-icon-major {
    border-color: #D9534F !important;
}

.switch-icon-minor {
    border-color: #c4a51b !important;
}

.switch-icon-normal {
    border-color: #2c1a9c !important;
}

.normal {
	.switch-value.active {
		border-color: #2c1a9c;
		background: #2c1a9c !important;
		color: #fff;
	}
}