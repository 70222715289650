.react-tabs {
  /* position: relative;
  white-space: nowrap;
  margin: 0 0 0 2px;
  width: auto; */
  /* text-align: center; */
  /* font-weight: 600; */
}

.react-tabs__tab-list {
  width: 100%;
  margin: 0;
  padding: 10px 10px 0 10px;
  list-style: none;
  border: 0;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  z-index: 1;
  position: relative;
}

.react-tabs__tab-list li{
  position: relative;
  white-space: nowrap;
  margin: 0 0 0 2px;
  width: auto;
  text-align: center;
  font-weight: 600;
}

.react-tabs__tab {
  display: block;
  padding: 5px 8px;
  font-size: 12px;
  text-decoration: none;
  line-height: 1.25;
  background: transparent;
  margin: 0px 0px 0px 2px;
  border-bottom: solid 3px #A2AAA4;
  cursor: pointer;
}

.react-tabs__tab:hover {
  border-color: #418FDE;
  color: #418FDE;
}

.react-tabs__tab--selected {
  border-color: #418FDE;
  color: #418FDE;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

/* .react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
} */

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.iframe-display{
  width: 100% !important;
  height: 46.5vh !important;
}
