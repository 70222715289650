.table.dataTable th:nth-child(2),.table.dataTable th:nth-child(3),.table.dataTable th:nth-child(4),
.table.dataTable th:nth-child(5),.table.dataTable th:nth-child(6),.table.dataTable th:nth-child(7),.table.dataTable th:nth-child(8),
.table.dataTable th:nth-child(9),.table.dataTable th:nth-child(10) {
    min-width: 45px !important;
}

.exception-header{
    background-color: #EFEFEF;
    margin-left:0;
    max-width:100%;
    padding: 3px 3px;
    align-items: center;
}

.exception-detail-name{
    color: #333F48;
    font-size: 12px;
    font-weight: 400;
}


.exception-header1 img{
    padding-left: 2px;
}
.exception-header1 h6{
    margin-left: 5px;
}
.exception-header1 .exception-img{
    display: flex;
    justify-content: right;

}
.exception-img{
    float:right;
    background-color:white;
}

.table.dataTable th {
    padding-bottom: 28px !important;
    height: auto;
    line-height: normal;
    background: #fff;
}

.exception-tbody td{
    background-color: #E8E8E8 !important;
}

.exception-row:hover td{
    background-color:#418FDE33 !important;
 }



label{
    color: #418FDE !important;
}
.custom-label {
    position: relative;
    width: 1rem;
    height: 1rem;
    display: grid;
    place-items: center;
  }
  
.custom-input, .custom-checkbox {
    position: absolute;
    width: inherit;
    height: inherit;
    border: 0.5px solid white;
  }
  
.custom-input { display: none; }
  
.custom-checkbox {
    display: grid;
    place-items: center;
    cursor: pointer;
    background-color: white;
  }
  
.firstbox { border-width: 1.5px; }
  
.custom-checkbox::after {
    content: url('/public/images/check.svg');
    position: absolute;
    font-size: 0.6rem;
    opacity: 0;
    transform: scale(0);
    transition: transform 0.25s, opacity 0.5s;
  }
  
.custom-input:checked ~ .custom-checkbox::after {
    opacity: 5;
    transform: scale(1);
  }