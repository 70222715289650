.v1-header {
    height: 95px;
    flex-shrink: 0;
    background-color: #FFF;

}

.v1-company-logo {
    width: 244px;
    height: 95px;
    text-align: center;
    padding: 18px 25px;
    border-right: 1px solid #A2AAA4;
}

.v1-top-header {
    padding-top: 13px;
    font-family: Acumin Pro;
    border-bottom: 1px solid #A2AAA4;
    height: 57px;
    width: 100%;
}

.v1-top-header h5 {
    color: #333F48;
    font-family: Acumin Pro;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 5px;
    margin-top: 5px;
}

.v1-label-name {
    margin-top: 5px;
    margin-left: 5px;
}

.v1-bottom-header {
    height: 37px;
}

.v1-header-timestamp-label {
    color: #A2AAA4;
    font-family: Acumin Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
    margin-right: 5px;
}

.v1-search-icon {
    margin-left: 38%;
}

.question-icon {
    margin: 0px 40px;
}

.v1-header-timestamp-value {
    color: #333F48;
    font-family: Acumin Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
}

.v1-menu-icon {
    margin-left: 60px;
}

.v1-notification-icon {
    margin-left: 25px;
}

.inner-v1-bottom-header {
    padding: 10px;
    width: 50vw;
}

.inner-v1-bottom-header span {
    color: #A2AAA4;
    font-family: Acumin Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.v1-link-icon {
    margin-top: 3px;
}

.v1-link-icon svg g path:hover {
    fill: #eaefeb;
}

.v1-range-filter {
    width: 300px;
    margin-top: -8px;
    margin-left: 40px;
}

.css-1tfve6q-MuiSlider-mark {
    width: 0px !important;
    height: 2px;
    border-radius: 1px;
    background-color: currentColor;
    top: 50%;
    -webkit-transform: translate(-1px, -50%);
    -moz-transform: translate(-1px, -50%);
    -ms-transform: translate(-1px, -50%);
    transform: translate(-1px, -50%);
}


.v1-load-button {
    /* border-radius: 15px;
    background-color: #13294B;
    padding: 12px 20px; */
    border-radius: 3px;
    background-color: #13294B;
    padding: 12px 22px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}