.dash-count {
    margin-top: 10px;
}

.dash-exception {
    margin: 0;
    width: 100%;
}

.dash-exception .sub-section {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px auto;
}

.sub-section .subsec1 {
    background-color: white;
    margin-left: 5.5%;
}

.sub-section .subsec2 {
    background-color: white;
    padding: 10px;

}

.sub-section .subsec3 {
    background-color: white;
    margin-right: 5.5%;
    padding: 10px;
}

.dash-exception-data-table {
    margin: 10px 5.6% 10px 5.6%;
}

.dash-maintainance-chart {
    margin: 10px 5.5% 10px 5.5%;
    background-color: white;
    padding: 10px;
}