.chart-heading {
    display: flex;
    align-items: center;
}

.chart-heading p {
    margin-left: 10px;
    margin-top: 5px;
}

.chart-content {
    height: 22rem;
    display: flex;
    align-items: center;
}

.chart-body {
    background-color: #E2E2E2;
    height: 3rem;
    width: 100%;
    border: 2px solid #707070;
    display: flex;
    align-items: center;

}

.notification-stack {
    width: 20%;
    height: 100%;
    background-color: #A9DBB0;
    text-align: center;
    display: flex;
    justify-content: center;
    /* justify-content: space-around; */
}

.notification-stack .event-warning {
    width: 50px;
    margin: 2px 0px;
    background-color: #F1C400;
    background-image: url(../../../../public/images/bell.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.notification-stack .event-danger {
    width: 50px;
    margin: 2px 0px;
    background-color: #B2292E;
    color: white;
    background-image: url(../../../../public/images/white-bell.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.order-stack {
    width: 15%;
    height: 100%;
    background-color: #E2E2E2;
    text-align: center;
    display: flex;
    justify-content: space-around;
}

.order-stack .order-planned {
    width: 50px;
    margin: 2px;
    background-color: #D86018;
    color: white;
    background-image: url(../../../../public/images/white-box.svg);
    background-repeat: no-repeat;
    background-position: center;
}


.order-stack .order-unplanned {
    width: 50px;
    margin: 2px;
    background-color: #fff;
    color: black;
    background-image: url(../../../../public/images/black-box.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.start-line {
    border-left: 3px solid #00AEC7;
    height: 90px;
    top: 0;
    /* padding-right: -6px; */
    margin-top: 46px;
}

.end-line {
    border-left: 3px solid #00AEC7;
    height: 120px;
    top: 0;
    margin-left: -3px;
    margin-top: 46px;
}

.notification-stack {
    margin-left: px;
}

.notification-stack .start-box {
    margin-top: 120px;
    height: 50px;
    width: 130px;
    margin-left: -132px;
    padding: 8px 0px;
}


.notification-stack .close-box {
    margin-top: 150px;
    height: 60px;
    width: 130px;
    padding: 8px 0px;
}

.order-line {
    border-left: 3px solid #00AEC7;
    height: 40px;
    margin-left: 1.5rem;
    top: 0;
    margin-top: 45px;
}

.order-box {
    margin-top: -5px;
    height: 15px;
    width: 130px;
    margin-left: -45px;
    padding: 8px 0px;
    color: black;

}
.exception-content{
    margin-top: -100px;
    position: absolute;
    width: 100px;
    margin-left: -130px;
}