.anchor-link span {
    color: #418fde;
    cursor: pointer;
}
.color-text{
    margin-left: 288px;
    color: red;
    cursor: pointer;
       
}
