.exception-card {
    margin: 15px;
    top: 247px;
    left: 30px;
    background-color: rgb(255, 255, 255);
    font-family: "Acumin Pro";
    justify-content: space-evenly;
}

.except-heading {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    width: 80%;
}

.except-table-header {
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

}

table tbody tr td {
    background-color: rgb(255, 255, 255);
    color: rgb(11, 7, 7);
    padding: 5px;
}

table {
    width: 100%;
}

.except-content1 {
    width: 402px;
    height: 50px;
    margin-top: 5px;
    align-items: center;
    display: flex;
    text-wrap: nowrap;
}

.except-content1.selected {
    background-color: #13294B;
    color: rgb(241, 211, 57);

}

.except-content1.unselected {
    background-color: #BDC6D4;
    color: rgb(201, 169, 11);
}

.except-content1 h5 {
    margin: 0px 10px;
}


.chart-container-scrollable {
    max-height: 500px;
    overflow-y: scroll;
}

.custom-data-label {
    font-weight: bold;
    text-align: end;
    padding: 2px 5px;
    /* Adjust padding as needed */
}

.except-count{
    position: relative;
    background-color: white;
}
.except-count div {
    margin: 5px;
}

.except-count div icon {
    border-radius: 50%;
    color: #fff;
    padding: 5px 8px;
}
.except-count div span {
    padding: 5px 8px;
    font-weight: 700;
}