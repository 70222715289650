.machine-card {
    margin: 15px;
    top: 247px;
    left: 30px;
    background-color: rgb(255, 255, 255);
    font-family: "Acumin Pro";
    padding: 5px;
    border-radius: 2px;
}

.except-heading {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.except-table-header {
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding: 5px 0px;
    /* justify-content: space-evenly; */
}
   
.except-table-header p{
    margin-right: 3rem;
}

table tbody tr td {
    background-color: rgb(255, 255, 255);
    color: rgb(11, 7, 7);
    padding: 5px;
    /* margin-top: px; */
}


.severity-Major .severity-box {
    background-color: rgb(234, 57, 8);
    height: 35px;
    width: 6px;
}

.severity-Critical .severity-box {
    background-color: rgb(174, 24, 24);
    height: 35px;
    width: 6px;
}

.severity-Minor .severity-box {
    background-color: rgb(222, 206, 37);
    height: 35px;
    width: 6px;
}
.severity-None .severity-box {
    background-color: #2c1a9c;
    height: 35px;
    width: 6px;
}



tr:hover .bg-hover {
    background-color: #13294B;
    color: #fff;
}
.bg-hover.selected {
    background-color: #13294B;
    color: #fff;
}

.except-content .except-selected {
    background-color: #13294B;
    color: white;
    border-top: 3px solid #467ed2;
    border-bottom: 3px solid #467ed2;
}

.except-content .left {
    border-left: 3px solid #467ed2;
}

.except-content .right {
    border-right: 3px solid #467ed2;
}

.except-content .except-unselected {
    background-color: #ffffff;
    color: rgb(11, 10, 10);
}

.machine-count-box {
    background-color: #BDC6D4;
    height: 45px !important;
    width: 51px !important;
    text-align: center;
}

.except-sub-heading {
    height: 480px;
    overflow-y: scroll;
}