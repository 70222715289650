.add-event-form-block{
    height: 90vh;
    overflow-y: auto;
    &::-webkit-scrollbar{
        width: 6px;
		height: 6px;
    }
    &::-webkit-scrollbar-track-piece {
		background: rgba(0, 0, 0, 0.1);
		border-radius: 16px;
		width: 10px;
	}
	&::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.75);
		border-radius: 16px;
	}
}

textarea {
    color: #000;
}

.error{
	color: red;
}

.add-event-form-block {
	.form-group{
		margin-bottom: 0px;
	}

	hr:before{
		background: none;
	}
}

.custom-input textarea {
    padding: 8px 42px 5px 15px;
    appearance: none;
    position: relative;
    z-index: 1;
    background: #f3f3f3;
    line-height: normal;
    border: 1px solid transparent;
    cursor: pointer;
    width: -webkit-fill-available;
}






/* fallback, until https://github.com/CompuIves/codesandbox-client/issues/174 is resolved */
@font-face {
font-family: "Material Icons";
font-style: normal;
font-weight: 400;
src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
	format("woff2");
}

.material-icons {
font-family: "Material Icons";
font-weight: normal;
font-style: normal;
font-size: 24px;
line-height: 1;
letter-spacing: normal;
text-transform: none;
display: inline-block;
white-space: nowrap;
word-wrap: normal;
direction: ltr;
-webkit-font-feature-settings: "liga";
-webkit-font-smoothing: antialiased;
}

/* mdl-demo is a custom classname to increases the specificity of our styles. It can be anything. 
* The idea is that it is easy to extend/override builtin styles with very little effort.
*/

.mdl-demo  {
	.dropdown-trigger{
		&:hover{
			color: #000;
		}
	}
	
	.tag-list{
		height: calc(2.25rem + 2px);
		appearance: none;
		position: relative;
		z-index: 1;
		background: #f3f3f3;
		line-height: normal;
		border-radius: 50px;
		border: 1px solid transparent;
		cursor: pointer;
		width: -webkit-fill-available;
	}
	.tag-remove{
		display: none;
	}
	.tag-item input{
		height: calc(2.25rem + 2px);
		padding: 8px 42px 5px 15px;
		appearance: none;
		position: relative;
		z-index: 3;
		background: transparent;
		line-height: normal;
		border-radius: 50px;
		border: 1px solid transparent;
		cursor: pointer;
		width: -webkit-fill-available;

	}
	.dropdown-content{
		position: absolute;
		z-index: 999;
		border: 1px solid grey;
		box-shadow: 0 1px 12px rgba(0, 0, 0, 0.5);
		border-radius: 5px;
		background-color: #fff;

		width: 100%;
		min-height: 200px;

		max-height: 200px;
		overflow: hidden;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 6px;
			height: 6px;
		}
		&::-webkit-scrollbar-track-piece {
			background: rgba(0, 0, 0, 0.2);
			border-radius: 16px;
			width: 10px;
		}
		&::-webkit-scrollbar-thumb {
			background: rgba(0, 0, 0, 0.4);
			border-radius: 16px;
		}
	}
	
	.tag {
		background-color: #f4f4f4;
		border-radius: 2px;
		position: absolute;
		left: 15px;
		align-items: center;
		justify-content: center;
		z-index: 2;
		margin-top: 10px;
	}

	.dropdown-content{
		li {
			line-height: 0.1;
			span {
				margin-right: 10px;
			}
			i {
				color: #000;
			}
			&:hover{
				background-color: #2196f3;
				span {
					color: #fff;
				}
				i {
					color: #fff;
				}
			}

		}

	}
	
	.node.tree.checked.focused{
		background-color: #2196f3;
		label{
			input {
				display: none;
			}
			span {
				color: #fff;
			}
		}
		
	}
	.node.tree.focused{
		background-color: #2196f3;
		label{
			input {
				display: none;
			}
			span {
				color: #fff;
			}

		}
		
	}
	.node.tree{
		label{
			input {
				display: none;
			}
		}
	}
	.node.leaf.focused{
		background-color: #2196f3;
		label{
			input {
				display: none;
			}
			span {
				color: #fff;
			}
		}
	}
	.node.leaf{
		label{
			input {
				display: none;
			}
		}
	}
}

.mdl-demo .dropdown-trigger > span:after {
	font-size: 12px;
	color: #555;
}

.mdl-demo .toggle {
	font: normal normal normal 18px/1 "Material Icons";
	color: #555;
	white-space: pre;
	margin-right: 4px;
}

.mdl-demo .toggle.collapsed::after {
	cursor: pointer;
	content: "\E5CF";
	vertical-align: middle;
}

.mdl-demo .toggle.expanded::after {
	cursor: pointer;
	content: "\E5CE";
	vertical-align: middle;
}

/* checkbox styles */
.mdl-demo .checkbox-item {
	position: relative;
	width: 1rem;
	height: 1rem;
	margin-right: 0.75rem;
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: 0;
	vertical-align: middle;
}

.mdl-demo .checkbox-item:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	z-index: 999;
	width: 100%;
	height: 100%;
	border: 2px solid #aaa;
	transition: all 0.3s ease-in-out;
}

.mdl-demo .checkbox-item:checked:before {
	height: 50%;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	border-top-style: none;
	border-right-style: none;
	border-color: #2196f3;
}

table.con-mon-table {
    td {
        vertical-align: middle;
        text-align: center;
        max-width: 138px;
    }
}